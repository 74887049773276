import clsx from 'clsx';
import { EmblaCarouselType } from 'embla-carousel';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

const arrowClasses =
  'absolute -top-7 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full md:-top-8 md:h-10 md:w-10';

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;
  return (
    <button
      aria-label="Go To Previous Page"
      className={clsx(
        arrowClasses,
        'carousel-arrow carousel-arrow-prev',
        props.disabled && 'carousel-arrow-disabled opacity-20'
      )}
      type="button"
      {...restProps}>
      {children}
    </button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button
      aria-label="Go To Next Page"
      className={clsx(
        arrowClasses,
        'carousel-arrow carousel-arrow-next',
        props.disabled && 'carousel-arrow-disabled opacity-20'
      )}
      type="button"
      {...restProps}>
      {children}
    </button>
  );
};
